



































































































import { Component, Vue } from 'vue-property-decorator'
import { Configuration, RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import DniField from "@/components/landingVisa/DniField.vue";
import { EventBus } from "@/main";
import API from "@/api";

@Component({
	metaInfo: {
    title: 'Quiero que me contacten',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      valid: true,
      formulario: {
        ruc: "",
        nombre_empresa: "",
        nombre_contacto: "",
        numero_contacto: "",
        email_contacto: "" 
      },
      canPress: true,
      dialog: false,
      errorMessage: null,
      captcha: false,
      nameRules: [
        (v: any) => !!v || "Ingrese nombre",
        (v: any) =>
          v.length <= 255 || "Nombre no puede exceder los 255 caracteres."
      ],
      emailRules: [
        (v: any) => !!v || "Ingrese correo electrónico",
        (v: any) => /.+@.+/.test(v) || "La dirección debe ser válida"
      ],
      messageRules: [
        (v: any) => !!v || "Ingrese mensaje",
        (v: any) =>
          v.length >= 5 || "El mensaje debe tener al menos 5 caracteres."
      ],
      rulephone: [
        (v: any) => !!v || 'Teléfono es requerido',
        (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros",
        (v: any) => v.length > 7 || "Minimo 8 caracteres",
        (v: any) => !!v || "Ingrese un valor"
      ],
      via: null,
    };
  },
  methods: {
    async validate(response) {
      this.$data.canPress = false;
      // @ts-ignore
      if (this.$refs.form.validate()) {
        API.contactoTarjetaVisa(
            this.$data.formulario.ruc,
            this.$data.formulario.nombre_empresa,
            this.$data.formulario.nombre_contacto,
            this.$data.formulario.numero_contacto,
            this.$data.formulario.email_contacto,
            this.$data.via,
        )
        .then((data: any) => {
            if(data.message == 'Error'){
                EventBus.$emit("snack-error", data.data);
                this.$data.canPress = true; 
            }else{
                this.$data.dialog = true;
                setTimeout(() => {
                    this.$data.dialog = false;
                    this.$router.push({ path: "/" });
                }, 2000);

            }
        })
      }
    },
    doRecaptcha() {
      // @ts-ignore
      this.$refs.recaptchaContact.execute();
    },
    },
    beforeMount(){
        if(this.$route.query.via){
            this.$data.via = this.$route.query.via;
        }
    },
	components: {
        DniField
    },
    mounted(){
		const script = document.createElement('script');
		script.src = '/js/hotjar_visa_contacto.js';
		script.async = true;
		document.head.appendChild(script);
	}
})

export default class Contacto extends Vue {}
