














import { Component, Vue, Prop } from "vue-property-decorator";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";


@Component({
  data() {
    return {
      SelectedCountry,
      RedCapital,
      dni: "",
      isFocus: false,
      rules: {
        ruc: [
          (v: any) =>
            !!v || `${RedCapitalConfig(SelectedCountry).DniName} es requerido`,
          (v: any) =>
            !(v.length > 11 || v.length < 11) ||
            `${RedCapitalConfig(SelectedCountry).DniName} no válido`
        ],
      }
    };
  },
  watch: {
    dni() {
      // @ts-ignore
      this.onChange();
    },
    value(val) {
      this.$data.dni = val;
    }
  },
  created() {
    this.$data.dni = this.$props.value;
  },
  methods: {
    onChange() {
      this.$emit("input", this.$data.dni);
    }
  }
})
export default class DniField extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() value!: string;
  @Prop() label!: string;
  @Prop() prefix!: string;
  @Prop() suffix!: string;
}
